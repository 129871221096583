<template>
  <van-tabbar
    v-model="active"
    style="z-index: 1999"
    active-color="var(--theme-color)"
    inactive-color="#666666"
  >
    <van-tabbar-item
      v-for="(tab, index) in tabbar"
      :to="tab.path"
      :dot="tab.dot"
      :info="tab.info"
      :key="index"
    >
      <span>{{ tab.name }}</span>
      <template #icon="props">
        <span v-if="props.active" :class="index == 0 ? 'iconfont icon-home_red' : 'iconfont icon-apply_red'"></span>
        <img v-else
          :src="props.active ? tabbar[index].iconActive : tabbar[index].icon"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>


<script>
import { Tabbar, TabbarItem } from "vant";
import homeActive from "@/assets/home/home_red.png";
import home from "@/assets/home/home_grey.png";
import apply from "@/assets/home/apply_grey.png";
import applyActive from "@/assets/home/apply_red.png";

export default {
  data() {
    return {
      active: 0,
      tabbar: [
        {
          name: "首页",
          path: "/",
          pathName: "home",
          dot: false,
          info: "",
          icon: home,
          iconActive: homeActive,
        },
        {
          name: "报名",
          path: "/apply",
          pathName: "apply",
          dot: false,
          info: "",
          icon: apply,
          iconActive: applyActive,
        },
      ],
    };
  },

  watch: {
    $route: "changeActive",
  },

  created() {
    const toName = this.$route.name;
    this.setActive(toName);
  },

  methods: {
    changeActive({ name }) {
      this.setActive(name);
    },
    setActive(name) {
      // console.log(name);

      this.tabbar.forEach((tab, i) => {
        if (tab.pathName == name) {
          this.active = i;
          return false;
        }
      });
    },
  },

  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
};
</script>

<style lang="less" scoped>
.iconfont{
  font-size: 21px;
}
</style>